import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { useSetRecoilState } from "recoil";
import { RolePermissionsContext } from "../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../../RAFComponents/Navigation/RAFDeletedRecordState";
import { hasPermission } from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  IsNotNullOrWhiteSpace,
  deepEqual,
  isNotEmptyArray,
  isNotNullAndUndefined,
  joinStringArray,
} from "../../../../RAFComponents/helpers/utils";
import { RolePermissionRow } from "../../../../RAFComponents/models/Common/RolePermissionRow";
import {
  CarePlanPermissionConstants,
  CareRecipientPermissionConstants,
} from "../../../../constants/CareESIO/CareESIOPermissionConstant";
import {
  CareEsioEntity,
  RAFCareRecipientType,
} from "../../../../constants/CareESIO/CareEsioConstants";
import {
  BrowserIsDevice,
  RAFButtonConstant,
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../../constants/Common/PermissionConstants";
import DocumentSummary from "../../../ActiveContacts/Document/DocumentSummary";
import DetailsSectionFieldsCustom from "../../../Common/Details/DetailsSectionFieldsCustom";
import { RAFObjectContext } from "../../../Common/Providers/RAFObjectContextProvider";
import CareAssessmentList from "../../CareAssessmentLibrary/CareAssessmentList";
import CarePlanDetails from "../../CarePlan/CarePlanDetails";
import { atomSelectedRecipientRightContent } from "../../CareRecipientHelper";
import CareServiceContractList from "../../CareServiceContract/CareServiceContractList";
import ChecklistItemTransListCardContent from "../../ChecklistItemTrans/ChecklistItemTransListCardContent";

interface IProps {
  careRecipientId?: string;
  selectedTab?: string;
  uiStyle?: "Tab" | "Card";
}

interface IState {
  selectedTabItem: {
    text: string;
    value: string;
  };
}

const getCareTabItems = (employee: any, permissionValue: RolePermissionRow) => {
  let careTabItems: {
    text: string;
    value: string;
    iconCss: string;
    permissionName: string;
  }[] = [];

  let employeeType =
    isNotNullAndUndefined(employee) && isNotNullAndUndefined(employee.Type)
      ? employee.Type
      : null;

  if (employeeType === RAFCareRecipientType.Lead) {
    careTabItems = [
      {
        text: `${isNotNullAndUndefined(employee) &&
          isNotNullAndUndefined(employee.first_name)
          ? `${employee.first_name}'s`
          : "Client"
          } Profile`,
        value: CareEsioEntity.CareRecipient.EntityName,
        iconCss: "fas fa-user",
        permissionName: CareRecipientPermissionConstants.CareRecipientRead,
      },
      {
        text: "Agreements",
        value: CareEsioEntity.CareServiceContract.EntityName,
        iconCss: "fas fa-history",
        permissionName:
          CareRecipientPermissionConstants.CareRecipientViewServiceAgreements,
      },
      {
        text: CareEsioEntity.CareAssessment.CollectionName,
        value: CareEsioEntity.CareAssessment.EntityName,
        iconCss: "fas fa-notes-medical",
        permissionName: null,
      },
      {
        text: "Compliance",
        value: CareEsioEntity.ChecklistItemTrans.EntityName,
        iconCss: "fas fa-file-alt",
        permissionName:
          CareRecipientPermissionConstants.CareRecipientViewDocuments,
      },
      {
        text: "Documents",
        value: RAFEntityName.ContentLibrary,
        iconCss: "fas fa-file-alt",
        permissionName: PermissionConstants.DocumentRead,
      },
    ];
  } else {
    careTabItems = [
      {
        text: `${isNotNullAndUndefined(employee) &&
          isNotNullAndUndefined(employee.first_name)
          ? `${employee.first_name}'s`
          : "Client"
          } Profile`,
        value: CareEsioEntity.CareRecipient.EntityName,
        iconCss: "fas fa-user",
        permissionName: CareRecipientPermissionConstants.CareRecipientRead,
      },
      {
        text: "Care Details",
        value: CareEsioEntity.CarePlan.EntityName,
        iconCss: "fas fa-notes-medical",
        permissionName: CarePlanPermissionConstants.CarePlanRead,
      },

      {
        text: "Agreements",
        value: CareEsioEntity.CareServiceContract.EntityName,
        iconCss: "fas fa-history",
        permissionName:
          CareRecipientPermissionConstants.CareRecipientViewServiceAgreements,
      },

      {
        text: CareEsioEntity.CareAssessment.CollectionName,
        value: CareEsioEntity.CareAssessment.EntityName,
        iconCss: "fas fa-notes-medical",
        permissionName: null,
      },
      {
        text: "Compliance",
        value: CareEsioEntity.ChecklistItemTrans.EntityName,
        iconCss: "fas fa-file-alt",
        permissionName:
          CareRecipientPermissionConstants.CareRecipientViewDocuments,
      },
      {
        text: "Documents",
        value: RAFEntityName.ContentLibrary,
        iconCss: "fas fa-file-alt",
        permissionName:
          CareRecipientPermissionConstants.CareRecipientViewDocuments,
      },
    ];
  }

  const permisionedCareTabItems = careTabItems.filter((x) =>
    x.permissionName ? hasPermission(permissionValue, x.permissionName) : true
  );

  return permisionedCareTabItems;
};

function AboutClient({
  uiStyle = "Tab",
  careRecipientId,
  ...props
}: PropsWithChildren<IProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      selectedTabItem: {
        text: "About Me",
        value: CareEsioEntity.CareRecipient.EntityName,
      },
    }
  );

  useEffect(() => {
    if (IsNotNullOrWhiteSpace(props.selectedTab)) {
      let careTabItems = getCareTabItems(null, permissionValue);

      if (isNotEmptyArray(careTabItems)) {
        const defaultTabItem = careTabItems.find(
          (x) => x.value.toLowerCase() === props.selectedTab.toLowerCase()
        );
        if (
          isNotNullAndUndefined(defaultTabItem) &&
          !deepEqual(defaultTabItem, state.selectedTabItem)
        ) {
          setState({ selectedTabItem: defaultTabItem });
        }
      }
    }
  }, [props.selectedTab]);

  const rafObject = React.useContext(RAFObjectContext);
  const currentObject = rafObject.rafObject;

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  const careRecipientModule = CareEsioEntity.CareRecipient.EntityName;

  const setSelectedRecipientRightContent = useSetRecoilState(
    atomSelectedRecipientRightContent(careRecipientModule)
  );

  const onTabSelected = (e) => {
    setState({ selectedTabItem: e });
  };

  async function checkScrollable() {
    const div = await document.querySelector("#raf_tab_header");
    const leftButton = await document.getElementById(
      "btn_raf_tab_header_left_scroll_iconBtn"
    );
    const rightButton = await document.getElementById(
      "btn_raf_tab_header_right_scroll_iconBtn"
    );

    if (div && leftButton && rightButton) {
      // Check if the div is scrollable
      if (div.scrollWidth > div.clientWidth) {
        // The div is scrollable, show the arrow buttons
        leftButton.style.display = "block";
        rightButton.style.display = "block";

        // Add click event listeners to the arrow buttons
        leftButton.addEventListener("click", () => {
          // Scroll the div to the left
          div.scrollTo({
            left: div.scrollLeft - (div.clientWidth - 100),
            behavior: "smooth",
          });
        });

        rightButton.addEventListener("click", () => {
          // Scroll the div to the right
          div.scrollTo({
            left: div.scrollLeft + (div.clientWidth - 100),
            behavior: "smooth",
          });
        });
      } else {
        // The div is not scrollable, hide the arrow buttons
        leftButton.style.display = "none";
        rightButton.style.display = "none";
      }

      // Stop observing the DOM changes
      observer.disconnect();
    }
  }

  // Create a MutationObserver to watch for changes in the DOM
  const observer = new MutationObserver(checkScrollable);

  // Start observing the document with the configured parameters
  observer.observe(document, { childList: true, subtree: true });

  function renderTabContent(
    selectedItem,
    rafObject,
    careTabItems: {
      text: string;
      value: string;
      iconCss: string;
      permissionName: string;
    }[]
  ) {
    selectedItem = isNotNullAndUndefined(selectedItem)
      ? selectedItem
      : state.selectedTabItem.value;
    const careRecipientName = isNotNullAndUndefined(rafObject)
      ? isNotNullAndUndefined(rafObject.recipient_name)
        ? rafObject.recipient_name
        : joinStringArray([
          rafObject.first_name,
          rafObject.middle_name,
          rafObject.last_name,
        ])
      : "";

    if (selectedItem === CareEsioEntity.CareRecipient.EntityName) {
      return (
        <div className="p-2 p-md-3" id={`care_recipient_${selectedItem}`}>
          {careDetailsContent()}
        </div>
      );
    } else if (selectedItem === CareEsioEntity.CarePlan.EntityName) {
      return (
        <div className="h-100" id={`care_recipient_${selectedItem}`}>
          {carePlanDetailsContent(careRecipientName)}
        </div>
      );
    } else if (selectedItem === CareEsioEntity.CareAssessment.EntityName) {
      return (
        <div className="h-100 p-2" id={`care_recipient_${selectedItem}`}>
          {assessmentContent(careRecipientName)}
        </div>
      );
    } else if (selectedItem === CareEsioEntity.ChecklistItemTrans.EntityName) {
      return (
        <div className="h-100" id={`care_recipient_${selectedItem}`}>
          {getTransDocument(careRecipientName)}
        </div>
      );
    } else if (selectedItem === RAFEntityName.ContentLibrary) {
      return (
        <div className="h-100" id={`care_recipient_${selectedItem}`}>
          {documentsContent(careRecipientName)}
        </div>
      );
    } else if (selectedItem === CareEsioEntity.CareServiceContract.EntityName) {
      return (
        <div className="h-100" id={`care_recipient_${selectedItem}`}>
          {careServiceContractContent(careRecipientName)}
        </div>
      );
    }
  }

  const assessmentContent = (careRecipientName) => {
    return (
      <CareAssessmentList
        careRecipientUID={currentObject.UID}
        activeCarePlan={null}
        uiStyle="DetailsStyle"
      />
    );
  };

  const careServiceContractContent = (careRecipientName) => {
    return (
      <CareServiceContractList
        careRecipientUID={currentObject.UID}
        careRecipientName={careRecipientName}
      />
    );
  };

  const getTransDocument = (careRecipientName: string) => {
    return (
      <ChecklistItemTransListCardContent
        relatedUID={currentObject.UID}
        relatedTo={careRecipientName}
        relatedToType={careRecipientModule}
        readCompliancePermissionName={
          CareRecipientPermissionConstants.CareRecipientViewCompliance
        }
        manageCompliancePermissionName={
          CareRecipientPermissionConstants.CareRecipientManageCompliance
        }
        uploadCompliancePermissionName={
          CareRecipientPermissionConstants.CareRecipientUploadComplianceDocument
        }
        deleteDocumentPermissionName={
          CareRecipientPermissionConstants.CareRecipientDeleteDocuments
        }
        approveCompliancePermissionName={
          CareRecipientPermissionConstants.CareRecipientApproveComplianceDocument
        }
        commentCompliancePermissionName={
          CareRecipientPermissionConstants.CareRecipientCommentComplianceDocument
        }
      />
    );
  };

  const documentsContent = (careRecipientName: string) => {
    return (
      <DocumentSummary
        //key={state.documentSummaryKey}
        relatedUID={currentObject.UID}
        relatedTo={careRecipientName}
        relatedToType={careRecipientModule}
        moduleName={careRecipientModule}
        isRelatedSection
        isAdaptive={false}
        allowFiltering={false}
        Collapsible={false}
        IsCollapsed={false}
        //hideHeader={true}
        //addBtnDisplayStyle={"SectionFooter"}
        gridStyle="List"
        uiStyle="Card"
        addBtnClassname="btn_brand_primary semi_dark"
        readPermissionName={
          CareRecipientPermissionConstants.CareRecipientViewDocuments
        }
        deletePermissionName={
          CareRecipientPermissionConstants.CareRecipientDeleteDocuments
        }
        createPermissionName={
          CareRecipientPermissionConstants.CareRecipientManageDocuments
        }
        updatePermissionName={
          CareRecipientPermissionConstants.CareRecipientManageDocuments
        }
      />
    );
  };

  const carePlanDetailsContent = (careRecipientName) => {
    return (
      <CarePlanDetails
        careRecipientUID={currentObject.UID}
        careRecipientName={careRecipientName}
        uimode="Tab"
      />
    );
  };

  const careDetailsContent = () => {
    return (
      <div className={!BrowserIsDevice ? "" : "w-100"}>
        <div
          key={
            isNotNullAndUndefined(state.selectedTabItem)
              ? state.selectedTabItem.value
              : ""
          }
        >
          <div>
            {/* <div className="col-12">
              {BrowserIsDevice && profileInfoDivHeader()}
            </div> */}
            <DetailsSectionFieldsCustom
              contextModuleName={CareEsioEntity.CareRecipient.EntityName}
              contextObjectUID={currentObject.UID}
              uiStyle="Tab"
            ></DetailsSectionFieldsCustom>
          </div>
        </div>
      </div>
    );
  };

  const getTabContent = (
    rafObject,
    careTabItems: {
      text: string;
      value: string;
      iconCss: string;
      permissionName: string;
    }[]
  ) => {
    let careTabItemList = careTabItems;

    return (
      <div className="h-100 w-100">
        <CustomCardWidget
          headerTemplate={
            <div className="d-flex align-items-center surface_neutral_base">
              {currentObject.Type === RAFCareRecipientType.Lead
                ? ""
                : !BrowserIsDevice && (
                  <div
                    className={`d-flex align-items-center ps-3${!BrowserIsDevice ? " pointer" : ""
                      }`}
                    {...(!BrowserIsDevice && {
                      onClick: () => setSelectedRecipientRightContent(null),
                    })}
                  >
                    <div className="section__primary__header__backBtn me-2 content_neutral_dark mt-1">
                      <i
                        className={`${RAFButtonConstant.ArrowBack.IconCss}`}
                      ></i>
                    </div>
                    <div className="group_btn_separator"></div>
                  </div>
                )}
              {uiStyle === "Tab" ? (
                <div
                  id="raf_tab_header_container"
                  className="raf_tab_header raf_lg hideScrollBar surface_neutral_base"
                >
                  {/* <div className="px-3 d-flex align-items-center pointer"
                  onClick={() => setSelectedRecipientRightContent(null)}
                >
                  <i className={`${RAFButtonConstant.ArrowBack.IconCss} content_neutral_dark`}></i>
                  {/* <span className="subtitle_2 ps-2">Back</span> */}
                  {/*</div> */}
                  {!BrowserIsDevice && (
                    <RAFButtonComponent
                      iconBtn
                      iconCss="fas fa-chevron-left"
                      className="bg-white border-0 custom-button-lg"
                      id="raf_tab_header_left_scroll"
                      enableToolTip={false}
                    ></RAFButtonComponent>
                  )}
                  <div
                    id="raf_tab_header"
                    className="raf_tab_header raf_lg hideScrollBar"
                  >
                    {careTabItemList.map((x) => {
                      const permission =
                        isNotNullAndUndefined(x.permissionName) &&
                          isNotNullAndUndefined(permissionValue)
                          ? hasPermission(permissionValue, x.permissionName)
                          : true;

                      if (permission) {
                        return (
                          <div
                            className={`raf_tab_header_item${state.selectedTabItem.value === x.value
                              ? " active"
                              : ""
                              }`}
                            key={x.value}
                            onClick={() => onTabSelected(x)}
                          >
                            <span>{x.text}</span>
                          </div>
                        );
                      }
                    })}
                  </div>
                  {!BrowserIsDevice && (
                    <RAFButtonComponent
                      iconBtn
                      iconCss="fas fa-chevron-right"
                      className="bg-white border-0 custom-button-lg"
                      id="raf_tab_header_right_scroll"
                      enableToolTip={false}
                    ></RAFButtonComponent>
                  )}
                </div>
              ) : (
                <div className="section__secondary__header raf_sm bg-white">
                  <div
                    className="d-flex align-items-center pointer"
                    {...(!BrowserIsDevice && {
                      onClick: () => setSelectedRecipientRightContent(null),
                    })}
                  >
                    {!BrowserIsDevice && (
                      <div className="section__primary__header__backBtn me-2 content_neutral_dark mt-1">
                        <i
                          className={`${RAFButtonConstant.ArrowBack.IconCss}`}
                        ></i>
                      </div>
                    )}
                    <span className="subtitle_2">
                      {
                        careTabItemList.find(
                          (x) => x.value === state.selectedTabItem.value
                        )?.text
                      }
                    </span>
                  </div>
                </div>
              )}
            </div>
          }
          cardContentClassName="p-0 raf_tab_content section__div section__divY"
          cardClassName={`section__container overflow-hidden${BrowserIsDevice ? " border-0" : ""
            }`}
          showCardSeparator
        >
          {renderTabContent(null, rafObject, careTabItemList)}
        </CustomCardWidget>
      </div>
    );
  };

  if (
    isNotNullAndUndefined(currentObject) &&
    IsNotNullOrWhiteSpace(currentObject.UID)
  ) {
    const careTabItems = getCareTabItems(currentObject, permissionValue);

    return getTabContent(currentObject, careTabItems);
  } else {
    return (
      <div className="container-fluid px-0">
        <RAFDeletedRecordState
          title="This item is either deleted or You do not have sufficient privileges to view this item."
          showBackBtn
        />
      </div>
    );
  }
}

export default React.memo(AboutClient);
